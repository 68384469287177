import { get } from 'lodash';
import {
  getCurrentUser,
  isBlocked,
  getAppConfig,
  generatePostPageSEOTags,
  getCustomPostUrl,
  getCategoriesMap,
  resolveId,
} from '@wix/communities-blog-client-common';
import {
  SANTA_MEMBERS_APP_ID,
  MEMBERS_ABOUT_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { extractMedia } from 'ricos-content/libs/extract-media'; // eslint-disable-line import/no-extraneous-dependencies

import { Router } from '../../common/router';
import {
  ROUTE_404,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_LAST_POST,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_LOGIN,
} from '../constants/routes';
import {
  fetchPost,
  preFetchPost,
  completeFetchPost,
} from '../../common/actions/fetch-post';
import { fetchLastPost } from '../actions/fetch-last-post';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';
import { resolvePostSlug } from '../../common/services/slug';
import { pageOpened } from '../../common/actions/page-opened';
import {
  POST_PAGE,
  POST_EDIT_PAGE,
  POST_PAGE_NOT_FOUND,
} from '../../common/services/detect-route';
import { setIsPostInPreview } from '../../common/store/is-post-in-preview/set-is-post-in-preview-action';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { createPermissionsChecker } from '../../common/services/create-permission-helpers';
import {
  getIsMobile,
  isSeo,
  isSSR,
  isEditor,
  isSite,
  isPreview,
} from '../../common/store/basic-params/basic-params-selectors';
import {
  getIsRecentPostsEnabled,
  getIsRelatedPostsEnabled,
} from '../../common/selectors/app-settings-selectors';
import createPermissionChecker from '../../common/services/create-permission-checker';

import { fetchInitialPostEditorData } from '../services/post-editor';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { incrementPostViewCount } from '../../common/actions/increment-post-view-count';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { fetchPostMetadata } from '../../common/actions/fetch-post-metadata';
import { biOpenPostPageInEditor } from '../actions/open-post-page-editor';

import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { navigateToCustomPostUrl } from '../../common/actions/navigate-within-blog';
import { fetchProfileUrls } from '../../common/store/profile-urls/profile-urls-actions';
import { encodeURIComponentIfNeeded } from '../../common/services/uri';
import { setReadingSessionId } from '../actions/reading-session-id-actions';
import { getReadingSessionId } from '../selectors/reading-session-id-selector';

const assertUserLoggedInAndNotBlocked = (state, redirect) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return {
        isUserValid: false,
        redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED),
      };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const fetchRecentPostsAndComments = async (store, post, connections) => {
  const state = store.getState();
  const isClient = !isSSR(state);
  const dispatches = [];
  const relatedPostsEnabled = getIsRelatedPostsEnabled(state) !== false;
  const recentPostsEnabled = getIsRecentPostsEnabled(state);
  const hasRelatedPosts = post.relatedPostIds && post.relatedPostIds.length > 0;

  dispatches.push(() => store.dispatch(fetchPostMetadata(resolveId(post))));
  dispatches.push(() => store.dispatch(fetchProfileUrls()));

  if (recentPostsEnabled || (!hasRelatedPosts && relatedPostsEnabled)) {
    dispatches.push(() => store.dispatch(fetchRecentPosts(post, connections)));
  }

  if (relatedPostsEnabled && hasRelatedPosts) {
    dispatches.push(() =>
      store.dispatch(fetchRelatedPosts(post, connections)),
    );
  }

  if (isSeo(state)) {
    await Promise.all(dispatches.map((dispatch) => dispatch()));
  } else if (isClient) {
    dispatches.forEach((dispatch) => dispatch());
  }
};

const createPostPageRouter =
  (store, wixCodeApi, connections) =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const postSlug = resolvePostSlug(params);
    if (preFetch) {
      return store.dispatch(preFetchPost(postSlug));
    }

    const readingSessionId = `${parseInt(
      Math.random() * 10000000,
      10,
    )}-${Date.now()}`;
    store.dispatch(setReadingSessionId(readingSessionId));

    return (
      preFetchResult
        ? store.dispatch(completeFetchPost(postSlug, preFetchResult))
        : store.dispatch(fetchPost(postSlug))
    )
      .then(async (post) => {
        connections?.forEach((connection) => {
          if (connection.setPost) {
            connection.setPost(post);
          }
        });

        // HACK: multilingual language change in post page redirect to translation
        const lang = getQueryLocale(store.getState());
        if (lang && lang !== post.language) {
          const translation = post.translations.find(
            (trans) =>
              trans.language === lang && trans.status === POST_STATUS.published,
          );
          if (translation) {
            store.dispatch(
              navigateToCustomPostUrl(
                getCustomPostUrl(store.getState(), translation.slug),
                `/${translation.slug}`,
              ),
            );
            return;
          } else {
            const error = new Error('Post language mismatch');
            error.status = 404;
            throw error;
          }
        }

        if (post.status !== POST_STATUS.published) {
          redirect(`/${resolveId(post)}/edit`);
        }

        const state = store.getState();
        const isMobile = getIsMobile(state);
        const referrer = wixCodeApi.window.referrer;
        !isSSR(state) &&
          store.dispatch(
            pageOpened({
              page: POST_PAGE,
              post,
              isMobile,
              isEditor: isEditor(state),
              readingSessionId: getReadingSessionId(state),
              referrer,
            }),
          );

        if (!getIsDemoMode(state) && !isSSR(state)) {
          store.dispatch(incrementPostViewCount(resolveId(post)));
        }

        if (isSite(state)) {
          let mediaUrls;
          if (post.content) {
            try {
              mediaUrls = extractMedia(post.content).map(
                (media) => media.imageUrl || media.videoThumbnailUrl,
              );
            } catch (e) {}
          }

          let ownerUrl;
          try {
            const api = await wixCodeApi.site.getPublicAPI(
              SANTA_MEMBERS_APP_ID,
            );
            if (api) {
              ownerUrl = await api.getSectionUrl({
                appDefinitionId: MEMBERS_ABOUT_APP_ID,
                sectionId: 'about',
                memberId: post.owner.slug,
              });
            }
          } catch (e) {}

          const itemData = generatePostPageSEOTags({
            appConfig: getAppConfig(state),
            post,
            state,
            categoriesMap: getCategoriesMap(state),
            mediaUrls,
            customPostUrl: getCustomPostUrl(state, post.slug),
            multilingual: wixCodeApi.window.multilingual,
            ownerUrl,
          });

          const postPageSEOTags = {
            itemType: ITEM_TYPES.BLOG_POST,
            itemData,
            seoData: post.seoData,
          };

          wixCodeApi.seo.renderSEOTags(postPageSEOTags);
        }

        return fetchRecentPostsAndComments(store, post, connections);
      })
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };

const createPostPreviewPageRouter =
  (store) =>
  async ({ params }, redirect) => {
    if (!get(store.getState(), 'auth.isAuthenticated')) {
      return redirect(ROUTE_404);
    }

    const canPreviewPost = (state, post) => {
      const user = getCurrentUser(state);
      const perm = createPermissionChecker(state, user);
      const can = createPermissionsChecker(perm);
      return can('preview', 'post', post);
    };

    const postSlug = resolvePostSlug(params);

    return store
      .dispatch(
        fetchPost(postSlug, { includeDraft: true, instance: params.instance }),
      )
      .then((post) =>
        canPreviewPost(store.getState(), post)
          ? store.dispatch(setIsPostInPreview(true))
          : redirect(ROUTE_404),
      )
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };

const createPostEditPageRouter =
  (store) =>
  async ({ params }, redirect) => {
    const state = store.getState();

    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
      state,
      redirect,
    );
    if (!isUserValid) {
      return redirectState;
    }

    const postSlug = resolvePostSlug(params);
    return fetchInitialPostEditorData(state, store.dispatch, postSlug).then(
      () =>
        !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
    );
  };

const createPostCreatePageRouter = (store) => async (_route, redirect) => {
  const state = store.getState();

  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(
    state,
    redirect,
  );
  if (!isUserValid) {
    return redirectState;
  }

  const can = createPermissionsChecker(
    createPermissionChecker(state, getCurrentUser(state)),
  );
  if (!can('create', 'post')) {
    return redirect(ROUTE_404);
  }

  return fetchInitialPostEditorData(state, store.dispatch).then(
    () => !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
  );
};

const createLastPostPageRouter = (store, connections) => async (_route, redirect) => {
  const state = store.getState();
  if (!(isEditor(state) || isPreview(state))) {
    return redirect(ROUTE_404);
  }

  if (!isSSR(state)) {
    store.dispatch(biOpenPostPageInEditor());
  }

  const lastPost = await store.dispatch(fetchLastPost());
  if (lastPost) {
    connections?.forEach((connection) => {
      if (connection.setPost) {
        connection.setPost(lastPost);
      }
    });

    await fetchRecentPostsAndComments(store, lastPost, connections);
  }
};

const customRouteHandler = async (wixCodeApi) => {
  const path = `/${wixCodeApi.location.path
    .map(encodeURIComponentIfNeeded)
    .join('/')}`;
  const { key, segments } =
    (await wixCodeApi.site.getCustomizedUrlSegments(path)) || {};

  if (!key) {
    return null;
  }

  const slug = encodeURIComponentIfNeeded(segments.slug);
  const instance = encodeURIComponentIfNeeded(segments.instance);

  return (
    {
      [UrlMappingsKeys.BLOG_POST]: `/${slug}`,
      [UrlMappingsKeys.BLOG_POST_EDIT]: `/${slug}/edit`,
      [UrlMappingsKeys.BLOG_POST_PREVIEW]: `/${slug}/preview/${instance}`,
    }[key] || null
  );
};

export const createRouter = (
  store,
  _config,
  wixCodeApi,
  isCustomUrlEnabled,
  connections,
) => {
  const router = new Router();
  const basicParams = { viewMode: wixCodeApi.window.viewMode.toLowerCase() };

  if (isEditor({ basicParams }) || isPreview({ basicParams })) {
    router.add(ROUTE_LAST_POST, createLastPostPageRouter(store, connections));
  }

  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, wixCodeApi, POST_PAGE_NOT_FOUND, ROUTE_404),
  );
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_CREATE_POST, createPostCreatePageRouter(store));
  router.add(ROUTE_EDIT_POST, createPostEditPageRouter(store));
  router.add(ROUTE_PREVIEW_POST, createPostPreviewPageRouter(store));
  router.add(ROUTE_EDIT_COMMENT);
  router.add(ROUTE_POST, createPostPageRouter(store, wixCodeApi, connections), {
    preFetch: true,
  });
  router.add(
    ROUTE_POST_ADVANCED_SLUG,
    createPostPageRouter(store, wixCodeApi, connections),
    { preFetch: true },
  );
  router.add(ROUTE_EDIT_COMMENT_ADVANCED_SLUG);
  isCustomUrlEnabled &&
    router.addCustomRouteHandler(() => customRouteHandler(wixCodeApi));
  router.fallback(ROUTE_404);
  return router;
};
